.share
    center(800px)
    text-align center
    border-top 1px solid lightGray
    padding-top rem(20px)
    margin-top rem(10px)
    +above(cut)
        margin-top rem(40px)
    article &
        border-top none
    svg
        margin rem(15px)
        size(35,35)
    a
        text-decoration none
        border none
    .icon
        transition all .3s
    .icon-twitter:hover
        fill #1da1f3
    .icon-facebook:hover
        fill #3b5998
