.modal
    .mask
        position fixed
        top 0
        left 0
        right 0
        bottom 0
        z-index 1000
        background-color rgba(0,0,0,0.5)
        animation 1s fadeIn ease both
    .window
        position fixed
        top 50%
        left 50%
        z-index 1001
        transform translate(-50%,-50%)
        width "calc(100% - %s)" % rem(40px)
        max-width rem(800px)
        background-color #fff
        border-radius rem(10px)
        padding rem(5px) 0
        box-shadow 2px 2px 5px 2px rgba(0,0,0,0.3)
        animation .3s fadeIn .5s ease both
        +above(cut)
            min-width rem(400px)
        .close
            position absolute
            right rem(-10px)
            top rem(-10px)
            width rem(30px)
            height rem(30px)
            fill #fff
            background-color primaryDark
            border-radius 50%
            border 2px solid #fff
            padding rem(5px)
            cursor pointer
        .header
            text-align center
            border-bottom 1px solid lightGray
            padding rem(15px)
            h2, p
                margin 0
            p
                margin-top rem(10px)
                font-size rem(13px)
        .content
            max-height 90vh
            overflow auto
            padding rem(10px)
            ul
                margin 0
                padding 0
                list-style none
                display flex
                flex-direction column
                +above(cut)
                    flex-direction row
                li
                    flex 1
                    margin rem(15px)
                    figure
                        position relative
                        margin 0
                        &:after
                            content 'Lire maintenant'
                            text-transform uppercase
                            display inline-block
                            background themeColor
                            color #fff
                            font-size rem(13px)
                            font-weight 700
                            line-height rem(24px)
                            padding 0 rem(8px)
                            position absolute
                            bottom 10%
                            left -5px
                            transition background .2s
                    img
                        display block
                        max-width 100%
                        height auto
                        transition all .2s ease-in-out
                        position relative
                    a
                        font-weight normal
                        color primaryDark
                        text-decoration none
                        transition all .3s
                        &:hover
                            color rgba(0, 0, 0, .5)
                            img
                                -webkit-filter grayscale(100%)
                                filter grayscale(100%)
                            figure
                                &:after
                                    background primaryDark
                    h3
                        font-size rem(20px)
                        margin-bottom 0
                    p
                        display none
                        +above(cut)
                            display block
                            font-size rem(15px)
                            line-height rem(20px)
    &.closed
        display none
    &.exit
        .window
            animation .5s fadeIn .5s both, .5s zoomIn .5s both

