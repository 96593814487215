#markdown-toc, #markdown-toc ul, #markdown-toc ol, #markdown-toc li 
    font-size 1rem
    line-height 1rem

#markdown-toc, #markdown-toc ul, #markdown-toc ol 
    margin 0.5rem auto 0

#markdown-toc 
    background #f2f2f217
    border 1px solid #f2f2f2
    border-radius 5px
    padding-top 1rem
    padding-bottom 1rem
    margin-top -1rem
